@font-face {
    font-family: 'All-ProSans';
    src: url('../fonts/All-ProSans-Regular.woff2') format('woff2'),
    url('../fonts/All-ProSans-Regular.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'All-ProSans-Bold';
    src: url('../fonts/All-ProSans-Bold.woff2') format('woff2'),
    url('../fonts/All-ProSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'EndzoneSans-Bold';
    src: url('../fonts/EndzoneSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AllProDisaplayB-Bold';
    src: url('../fonts/AllProDisplayB-Bold.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'AllProDisaplayB-Medium';
    src: url('../fonts/AllProDisplayB-Medium.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'All-ProSans-Medium';
    src: url('../fonts/All-ProSans-Medium.woff2') format('woff2'),
    url('../fonts/All-ProSans-Medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
