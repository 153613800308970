body {
	font-family: All-ProSans, san-serif;
	background: #eeeeee;
}

input,
button {
	outline: none;
}

.prize-wrapper img {
	max-width: 100%;
}

@media screen and (max-width: 840px) {
	.hidden-mobile, .small-mobile, .standard-mobile-flex {
		display: none !important;
	}
	.standard-mobile-flex {
		display: flex !important;
	}
}

@media screen and (min-width: 840px) {
	.hidden-desktop, .small-mobile {
		display: none !important;
	}
}

@media screen and (max-width: 400px) {
	.standard-mobile-flex {
		display: none !important;
	}
	.small-mobile {
		display: block !important;
	}
}